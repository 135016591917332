import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SlideBrand.css'
// Import logos
import logo1xbet from './logo1stbet.webp';
import logo4rabet from './logo4rabet.webp';
import logoBinany from './logoBinany.webp';
import logobinomo from './logobinomo.webp';
import logomamaearth from './logomamaearth.webp';
import logoexpert from './logoexpert.webp';
import logoolampy from './logoolampy.webp';
import logopoker from './logopoker.webp';
import logorummy from './logorummy.webp';
import logompl from './logompl.webp';
import logoudic from './logoudic.webp';
import logobulbul from './logobulbul.webp';
import logowinzo from './logowinzo.webp';
import logojunglerummy from './logojunglerummy.webp';
import logomy11 from './logomy11.webp';
import logostar from './logostar.webp';
import logopari from './logopari.webp';
import logoget from './logoget.webp';


const logos = [
  logo1xbet, logo4rabet, logoBinany, logobinomo, 
  logomamaearth, logoexpert, logoolampy, logopoker, logorummy
];
const logos1 = [
    logompl, logoudic, logobulbul, logowinzo, 
    logojunglerummy, logomy11, logostar, logopari, logoget
  ];

const SlideBrand = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 4, // Adjust based on how many logos you want shown at once
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        cssEase: "linear",
        variableWidth: true,
        
    };
    const settings1 = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 4, // Adjust based on how many logos you want shown at once
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        cssEase: "linear",
        variableWidth: true,
        rtl: true
    };
    

    
    const [count, setCount] = useState(0);
    const countRef = useRef(null); // Ref for the counting element

    const startCounting = () => {
        for (let i = 0; i <= 100; i++) {
            setTimeout(() => {
                setCount(i);
            }, i * 20);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    startCounting();
                } else {
                    setCount(0);
                }
            });
        }, { threshold: 0.1 });

        if (countRef.current) {
            observer.observe(countRef.current);
        }

        return () => {
            if (countRef.current) {
                observer.unobserve(countRef.current);
            }
        };
    }, []);

    return (
        <>
        <div className="slide-brand-container" ref={countRef}>
            <h1>Trusted by <span>{count}+</span> Brands</h1>
            <p>We excel in cultivating enduring partnerships and achieving unparalleled results through a steadfast commitment to excellence and client satisfaction.</p>
           
        </div>
        <div className="Logo-brand-box">
            <Slider {...settings}>
                {logos.map((logo, index) => (
                    <div key={index}>
                        <img src={logo} alt={`Brand Logo ${index + 1}`} />
                    </div>
                ))}
            </Slider>
        </div>
        <div className="Logo-brand-box-2nd">
            <Slider {...settings1}>
                {logos1.map((logo, index) => (
                    <div key={index}>
                        <img src={logo} alt={`Brand Logo ${index + 1}`} />
                    </div>
                ))}
            </Slider>
        </div>
        
       
        </>
    );
};

export default SlideBrand;
