import React from 'react';
import './Header.css';
import { FiPhoneCall, FiMail } from 'react-icons/fi';
import logo from './logo.webp';
import { NavLink } from 'react-router-dom';
import Typewriter from 'typewriter-effect';



const Header = () => {
  
    return (
        <>
            
            <div className="main-header">
                <div className="company-branding">
                    <img src={logo} alt="Company Logo" className="company-logo" />
                    <span className="company-tagline">
                        <Typewriter
                            options={{
                                strings: ['Where Ads Influence & Inspire'],
                                autoStart: true,
                                loop: true,
                                delay: 75,
                                pauseFor: 10000,
                            }}
                        />
                    </span>
                </div>
               
                <nav className="header-navigation">
                    <NavLink to="/" className={({ isActive }) => isActive ? 'active' : ''}>HOME</NavLink>
                    <NavLink to="/services" className={({ isActive }) => isActive ? 'active' : ''}>SERVICES</NavLink>
                    <NavLink to="/portfolio" className={({ isActive }) => isActive ? 'active' : ''}>PORTFOLIO</NavLink>
                    <NavLink to="/influencers" className={({ isActive }) => isActive ? 'active' : ''}>INFLUENCERS</NavLink>
                    <NavLink to="/about" className={({ isActive }) => isActive ? 'active' : ''}>ABOUT</NavLink>
                    <NavLink to="/contact" className={({ isActive }) => isActive ? 'active' : ''}>CONTACT US</NavLink>
                </nav>
            </div>
        </>
    );
};

export default Header;
