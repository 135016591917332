import React from 'react';
import './HomeFooter.css';
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTurnDown } from '@fortawesome/free-solid-svg-icons';
const HomeFooter = () => {
  return (
    <>
    <footer className="home-footer">
     <div className="homefooter-aboutus">
        <h2>About US</h2>
        <p>Meta AdFox is a Marketing Agency that focuses on quality, innovation, & speed. But in reality it’s not really about us – It’s what we can do about you, what outcome we can bring.

We utilize technology to bring end results and grow our clients businesses. We pride ourselves in great work ethic, integrity, and end-results. International Marketing Agency has been able to create highly effective solutions in multiple verticals – SEO, PPC and Web Design services, while allowing our clients to implement effective and efficient lead generation processes and increase their revenue.
            </p></div>
     <div className="homefooter-navlinks">
    <h2>Links <FontAwesomeIcon icon={faTurnDown} /></h2>
        <Link to='/'>Home</Link>
        <Link to='/services'>Services</Link>
        <Link to='/portfolio'>Portfolio</Link>
        <Link to='/influencers'>Influencers</Link>
        <Link to='/about'>About</Link>
        <Link to='/contactUs'>Contact</Link>
     </div>
   
    </footer>
    <div className="copy-home-text">
     Copyright © 2024 - All right reserved © Meta AdFox
     </div>
    </>
  );
}

export default HomeFooter;
