import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import './Home.css'; // Ensure this file is properly linked

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Metahome from './Metahome.mp4';
import stub from './stubborn.webp'
import indianhacker from './indianhacker.webp'
import crazy from './crazy.webp'
import trigger from './trigginsan.webp'
import hungry from './hungry.webp'
import m4 from './m4.webp'
import glam from './glam.webp'
import wander from './wander.webp'
import fukra from './fukra.webp'
import tiu from './tiu.webp'
import sumitcool from './sumitcool.webp'
import ramneek from './ramneek.webp'
import trt from './trt.webp'
import lakshay from './lakshay.webp'
import SlideBrand from './SlideBrand';
import HomeContactForm from './HomeContactForm';
import emailjs from 'emailjs-com';
import Social from './Social'




const Home = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
   


      // Function to send email for brands
      const sendEmailBrand = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_yxb9m1n', 'template_0zlt0od', e.target, 'pjniIkUaIPC_MDfie')
            .then((result) => {
                alert("Thank you! Your submission has been received."); // User-facing confirmation
                setTimeout(() => {
                    setIsModalOpen(false);
                    setModalContent('');
                }, 3000);
            }, (error) => {
                alert("Oops! Something went wrong. Please try again."); // User-facing error message
            });
    };
    
    
    const sendEmailInfluencer = (e) => {
      e.preventDefault();
      emailjs.sendForm('service_3662aud', 'template_mrmng3w', e.target, '1KoN6zH0YUCfe4k-y')
          .then((result) => {
              console.log(result.text); // Log success for debugging
              alert("Thank you! Your submission has been received."); // User-facing success alert
              setTimeout(() => {
                  setIsModalOpen(false);
                  setModalContent('');
              }, 3000); // Optional delay before closing the modal
          }, (error) => {
              console.log(error.text); // Log error for debugging
              alert("Oops! Something went wrong. Please try again."); // User-facing error alert
          });
  };
    useEffect(() => {
        AOS.init({
            duration: 1000, // values from 0 to 3000, with step 50ms
            once: false, // whether animation should happen only once - while scrolling down
        });
    }, []);
    

    const openModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };
    const redirectToExternalLink = () => {
        window.open('https://www.youtube.com/@MRINDIANHACKER', '_blank', 'noopener,noreferrer');
      }
    const redirectToExternalLink1 = () =>{
        window.open('https://www.youtube.com/@CrazyXYZ', '_blank', 'noopener,noreferrer')
    }
    const redirectToExternalLink2 = () =>{
      window.open('https://www.youtube.com/@triggeredinsaan', '_blank', 'noopener,noreferrer')
  }
    const redirectToExternalLink3 = () =>{
       window.open('https://www.youtube.com/@stubbornfacts', '_blank', 'noopener,noreferrer')
 }
    const redirectToExternalLink4 = () =>{
       window.open('https://www.youtube.com/@M4Techofficial', '_blank', 'noopener,noreferrer') 
 }
    const redirectToExternalLink5 = () =>{
       window.open('https://www.youtube.com/@FukraInsaan', '_blank', 'noopener,noreferrer')
}
const redirectToExternalLink6 = () =>{
  window.open('https://www.youtube.com/@hungrybirds4803', '_blank', 'noopener,noreferrer')
}
const redirectToExternalLink7 = () =>{
  window.open('https://www.youtube.com/@WanderersHub', '_blank', 'noopener,noreferrer')
}
const redirectToExternalLink8 = () =>{
  window.open('https://www.youtube.com/@RamneekSingh1313', '_blank', 'noopener,noreferrer')
}
const redirectToExternalLink9 = () =>{
  window.open('example.com', '_blank', 'noopener,noreferrer')
}
const redirectToExternalLink10 = () =>{
  window.open('example.com', '_blank', 'noopener,noreferrer')
}
const redirectToExternalLink11 = () =>{
  window.open('example.com', '_blank', 'noopener,noreferrer')
}
const redirectToExternalLink12 = () =>{
  window.open('example.com', '_blank', 'noopener,noreferrer')
}
const redirectToExternalLink13 = () =>{
  window.open('example.com', '_blank', 'noopener,noreferrer')
}


    return (
        <>
       <Social/>
        <div className="home-background">
        <video autoPlay muted loop playsInline id="homeBackgroundVideo">
    <source src={Metahome} type="video/mp4" />
    Your browser does not support HTML5 video.
  </video>
            <div className="home-text">
                <h1>
                    <span className="innovate">INNOVATE</span>
                    <span className="word-gap"></span>
                    <span className="influence">INFLUENCE</span>
                    <span className="word-gap"></span>
                    <span className="inspire">INSPIRE</span>
                </h1>
                <div className="Home-Buttons">
                <button class="btn" onClick={() => openModal('brand')}> Join as a Brand
                </button>

<button class="btn" onClick={() => openModal('influencer')}>I'm Influencer
 
</button>

                </div>
            </div>
            {isModalOpen && (
    <div className="modal">
        <div className="modal-content">
            <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span>
            {modalContent === 'brand' ? (
                <div>
                    <h2>Join as a Brand</h2>
                    <form onSubmit={sendEmailBrand}>
                        <label htmlFor="brandName">Brand Name:</label>
                        <input type="text" id="brandName" name="brandName" required />
                        <label htmlFor="websiteLink">Website/App Link:</label>
                        <input type="url" id="websiteLink" name="websiteLink" required />
                        <label htmlFor="representativeName">Representative Name:</label>
                        <input type="text" id="representativeName" name="representativeName" required />
                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" required />
                        <label htmlFor="phone">Phone Number:</label>
                        <input type="tel" id="phone" name="phone" required />

                        <fieldset>
                            <legend>Influencer Marketing Services:</legend>
                            <label htmlFor="youtube">
                                <input type="checkbox" id="youtube" name="service" value="Youtube" />
                                Youtube
                            </label>
                            <label htmlFor="instagram">
                                <input type="checkbox" id="instagram" name="service" value="Instagram" />
                                Instagram
                            </label>
                            <label htmlFor="googleAds">
                                <input type="checkbox" id="googleAds" name="service" value="GoogleAds" />
                                Google Ads
                            </label>
                            <label htmlFor="facebookAds">
                                <input type="checkbox" id="facebookAds" name="service" value="FacebookAds" />
                                Facebook Ads
                            </label>
                        </fieldset>

                        <button type="submit">Submit</button>
                    </form>
                </div>
            ) : modalContent === 'influencer' ? (
                <div>
                    <h2>Sign Up as an Influencer</h2>
                    <form onSubmit={sendEmailInfluencer}>
    <label htmlFor="influencerBrandName">Name</label>
    <input type="text" id="influencerBrandName" name="influencerBrandName" required />
    
    <label htmlFor="socialMediaLinks">Social Media Handles Link:</label>
    <input type="url" id="socialMediaLinks" name="socialMediaLinks" required placeholder="e.g., https://instagram.com/yourhandle" />
    
    <label htmlFor="influencerRepresentativeName">Representative Name:</label>
    <input type="text" id="influencerRepresentativeName" name="influencerRepresentativeName" required />
    
    <label htmlFor="influencerEmail">Email:</label>
    <input type="email" id="influencerEmail" name="influencerEmail" required placeholder='e.g., example@gmail.com'/>
    
    <label htmlFor="influencerPhone">Phone Number:</label>
    <input type="tel" id="influencerPhone" name="influencerPhone" required />
    
    <fieldset>
        <legend>Influencer Marketing Service:</legend>
        <label htmlFor="serviceYoutube">
            <input type="checkbox" id="serviceYoutube" name="influencerService" value="Youtube" />
            Youtube
        </label>
        <label htmlFor="serviceInstagram">
            <input type="checkbox" id="serviceInstagram" name="influencerService" value="Instagram" />
            Instagram
        </label>
    </fieldset>
    
    <button type="submit">Submit</button>
</form>
                </div>
            ) : null}
        </div>
    </div>
)}
        </div>
        <div className="frame2">
    <div className="new-loader"> {/* New div with class new-loader */}
        <div className="loader">
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__ball"></div>
        </div>
        <div className="top-influencers-heading">
            <h2>TOP INFLUENCERS</h2>
        </div>
        <div className="loader">
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__ball"></div>
        </div>

    </div>
   <div className='card-container'>
   <div class="card" data-aos= 'fade-left'>
      <div class="bg"> 
      <img src={indianhacker} alt="creator graphics" />
      <h2>
      MR. INDIAN HACKER <br/> <span>( 37.8M )</span> </h2>

<button className='Explore-Button' onClick={redirectToExternalLink}> 
  <span class="icon"><svg fill="none" height="33" viewBox="0 0 120 120" width="33" xmlns="http://www.w3.org/2000/svg"><path d="m120 60c0 33.1371-26.8629 60-60 60s-60-26.8629-60-60 26.8629-60 60-60 60 26.8629 60 60z" fill="#cd201f"></path><path d="m25 49c0-7.732 6.268-14 14-14h42c7.732 0 14 6.268 14 14v22c0 7.732-6.268 14-14 14h-42c-7.732 0-14-6.268-14-14z" fill="#fff"></path><path d="m74 59.5-21 10.8253v-21.6506z" fill="#cd201f"></path></svg></span>
  <span class="text1">Explore</span>
  <span class="text2">Visit Channel</span> 
</button>
      </div>
      
      <div class="blob"></div>
</div>
<div class="card" data-aos= 'fade-left'>
      <div class="bg"> 
      <img src={crazy} alt="creator graphics" />
      <h2>
      Crazy<br/> XYZ <br/> <span>( 30M )</span></h2>

<button className='Explore-Button' onClick={redirectToExternalLink1}> 
  <span class="icon"><svg fill="none" height="33" viewBox="0 0 120 120" width="33" xmlns="http://www.w3.org/2000/svg"><path d="m120 60c0 33.1371-26.8629 60-60 60s-60-26.8629-60-60 26.8629-60 60-60 60 26.8629 60 60z" fill="#cd201f"></path><path d="m25 49c0-7.732 6.268-14 14-14h42c7.732 0 14 6.268 14 14v22c0 7.732-6.268 14-14 14h-42c-7.732 0-14-6.268-14-14z" fill="#fff"></path><path d="m74 59.5-21 10.8253v-21.6506z" fill="#cd201f"></path></svg></span>
  <span class="text1">Explore</span>
  <span class="text2">Visit Channel</span> 
</button>
      </div>
      
      <div class="blob"></div>
</div>
<div class="card" data-aos= 'fade-left'>

      <div class="bg"> 
      <img src={trigger} alt="creator graphics" />
      <h2>
      Triggered Insaan <br/> <span>( 21.7M )</span></h2>

<button className='Explore-Button' onClick={redirectToExternalLink2}> 
  <span class="icon"><svg fill="none" height="33" viewBox="0 0 120 120" width="33" xmlns="http://www.w3.org/2000/svg"><path d="m120 60c0 33.1371-26.8629 60-60 60s-60-26.8629-60-60 26.8629-60 60-60 60 26.8629 60 60z" fill="#cd201f"></path><path d="m25 49c0-7.732 6.268-14 14-14h42c7.732 0 14 6.268 14 14v22c0 7.732-6.268 14-14 14h-42c-7.732 0-14-6.268-14-14z" fill="#fff"></path><path d="m74 59.5-21 10.8253v-21.6506z" fill="#cd201f"></path></svg></span>
  <span class="text1">Explore</span>
  <span class="text2">Visit Channel</span> 
</button>
      </div>
      
      <div class="blob"></div>
</div>
   <div class="card" data-aos= 'fade-left'>
      <div class="bg"> 
      <img src={stub} alt="creator graphics" />
      <h2>
Stubborn Facts <br/> <span>( 17.2M )</span></h2>

<button className='Explore-Button' onClick={redirectToExternalLink3}> 
  <span class="icon"><svg fill="none" height="33" viewBox="0 0 120 120" width="33" xmlns="http://www.w3.org/2000/svg"><path d="m120 60c0 33.1371-26.8629 60-60 60s-60-26.8629-60-60 26.8629-60 60-60 60 26.8629 60 60z" fill="#cd201f"></path><path d="m25 49c0-7.732 6.268-14 14-14h42c7.732 0 14 6.268 14 14v22c0 7.732-6.268 14-14 14h-42c-7.732 0-14-6.268-14-14z" fill="#fff"></path><path d="m74 59.5-21 10.8253v-21.6506z" fill="#cd201f"></path></svg></span>
  <span class="text1">Explore</span>
  <span class="text2">Visit Channel</span> 
</button>
      </div>
      
      <div class="blob"></div>
</div>


<div class="card" data-aos= 'fade-left'>
      <div class="bg"> 
      <img src={m4} alt="creator graphics" />
      <h2>
      M4 <br/> Tech <br/> <span>( 12.9M )</span></h2>

<button className='Explore-Button' onClick={redirectToExternalLink4}> 
  <span class="icon"><svg fill="none" height="33" viewBox="0 0 120 120" width="33" xmlns="http://www.w3.org/2000/svg"><path d="m120 60c0 33.1371-26.8629 60-60 60s-60-26.8629-60-60 26.8629-60 60-60 60 26.8629 60 60z" fill="#cd201f"></path><path d="m25 49c0-7.732 6.268-14 14-14h42c7.732 0 14 6.268 14 14v22c0 7.732-6.268 14-14 14h-42c-7.732 0-14-6.268-14-14z" fill="#fff"></path><path d="m74 59.5-21 10.8253v-21.6506z" fill="#cd201f"></path></svg></span>
  <span class="text1">Explore</span>
  <span class="text2">Visit Channel</span> 
</button>
      </div>
      
      <div class="blob"></div>
</div>
<div class="card" data-aos= 'fade-right'>
      <div class="bg"> 
      <img src={fukra} alt="creator graphics" />
      <h2>
      Fukra <br/>Insaan <br/> <span>( 10M )</span></h2>

<button className='Explore-Button' onClick={redirectToExternalLink5}> 
  <span class="icon"><svg fill="none" height="33" viewBox="0 0 120 120" width="33" xmlns="http://www.w3.org/2000/svg"><path d="m120 60c0 33.1371-26.8629 60-60 60s-60-26.8629-60-60 26.8629-60 60-60 60 26.8629 60 60z" fill="#cd201f"></path><path d="m25 49c0-7.732 6.268-14 14-14h42c7.732 0 14 6.268 14 14v22c0 7.732-6.268 14-14 14h-42c-7.732 0-14-6.268-14-14z" fill="#fff"></path><path d="m74 59.5-21 10.8253v-21.6506z" fill="#cd201f"></path></svg></span>
  <span class="text1">Explore</span>
  <span class="text2">Visit Channel</span> 
</button>
      </div>
      
      <div class="blob"></div>
</div>
<div class="card" data-aos= 'fade-left'>
      <div class="bg"> 
      <img src={hungry} alt="creator graphics" />
      <h2>
      Hungry <br/> Birds <br/> <span>( 9.86M )</span></h2>

<button className='Explore-Button' onClick={redirectToExternalLink6}> 
  <span class="icon"><svg fill="none" height="33" viewBox="0 0 120 120" width="33" xmlns="http://www.w3.org/2000/svg"><path d="m120 60c0 33.1371-26.8629 60-60 60s-60-26.8629-60-60 26.8629-60 60-60 60 26.8629 60 60z" fill="#cd201f"></path><path d="m25 49c0-7.732 6.268-14 14-14h42c7.732 0 14 6.268 14 14v22c0 7.732-6.268 14-14 14h-42c-7.732 0-14-6.268-14-14z" fill="#fff"></path><path d="m74 59.5-21 10.8253v-21.6506z" fill="#cd201f"></path></svg></span>
  <span class="text1">Explore</span>
  <span class="text2">Visit Channel</span> 
</button>
      </div>
      
      <div class="blob"></div>
</div>



   </div>
   <div className='card-container'>
   <div class="card" data-aos= 'fade-right'>
      <div class="bg"> 
      <img src={wander} alt="creator graphics" />
      <h2>
      Wanderers Hub <br/> <span>( 8.54M )</span></h2>

<button className='Explore-Button' onClick={redirectToExternalLink7}> 
  <span class="icon"><svg fill="none" height="33" viewBox="0 0 120 120" width="33" xmlns="http://www.w3.org/2000/svg"><path d="m120 60c0 33.1371-26.8629 60-60 60s-60-26.8629-60-60 26.8629-60 60-60 60 26.8629 60 60z" fill="#cd201f"></path><path d="m25 49c0-7.732 6.268-14 14-14h42c7.732 0 14 6.268 14 14v22c0 7.732-6.268 14-14 14h-42c-7.732 0-14-6.268-14-14z" fill="#fff"></path><path d="m74 59.5-21 10.8253v-21.6506z" fill="#cd201f"></path></svg></span>
  <span class="text1">Explore</span>
  <span class="text2">Visit Channel</span> 
</button>
      </div>
      
      <div class="blob"></div>
</div>
<div class="card" data-aos= 'fade-right'>
      <div class="bg"> 
      <img src={ramneek} alt="creator graphics" />
      <h2>Ramneek Singh 1313 <br/> <span>( 6.42M )</span>
      </h2>

<button className='Explore-Button' onClick={redirectToExternalLink8}> 
  <span class="icon"><svg fill="none" height="33" viewBox="0 0 120 120" width="33" xmlns="http://www.w3.org/2000/svg"><path d="m120 60c0 33.1371-26.8629 60-60 60s-60-26.8629-60-60 26.8629-60 60-60 60 26.8629 60 60z" fill="#cd201f"></path><path d="m25 49c0-7.732 6.268-14 14-14h42c7.732 0 14 6.268 14 14v22c0 7.732-6.268 14-14 14h-42c-7.732 0-14-6.268-14-14z" fill="#fff"></path><path d="m74 59.5-21 10.8253v-21.6506z" fill="#cd201f"></path></svg></span>
  <span class="text1">Explore</span>
  <span class="text2">Visit Channel</span> 
</button>
      </div>
      
      <div class="blob"></div>
</div>
<div class="card" data-aos= 'fade-left'>
      <div class="bg"> 
      <img src={glam} alt="creator graphics" />
      <h2>
      That Glam Girl <br/> <span>( 5.21M )</span> </h2>

<button className='Explore-Button' onClick={redirectToExternalLink9}> 
  <span class="icon"><svg fill="none" height="33" viewBox="0 0 120 120" width="33" xmlns="http://www.w3.org/2000/svg"><path d="m120 60c0 33.1371-26.8629 60-60 60s-60-26.8629-60-60 26.8629-60 60-60 60 26.8629 60 60z" fill="#cd201f"></path><path d="m25 49c0-7.732 6.268-14 14-14h42c7.732 0 14 6.268 14 14v22c0 7.732-6.268 14-14 14h-42c-7.732 0-14-6.268-14-14z" fill="#fff"></path><path d="m74 59.5-21 10.8253v-21.6506z" fill="#cd201f"></path></svg></span>
  <span class="text1">Explore</span>
  <span class="text2">Visit Channel</span> 
</button>
      </div>
      
      <div class="blob"></div>
</div>
<div class="card" data-aos= 'fade-right'>
      <div class="bg"> 
      <img src={trt} alt="creator graphics" />
      <h2>Top Real<br/> Team <br/> <span>( 5.18M )</span>
      </h2>

<button className='Explore-Button' onClick={redirectToExternalLink10}> 
  <span class="icon"><svg fill="none" height="33" viewBox="0 0 120 120" width="33" xmlns="http://www.w3.org/2000/svg"><path d="m120 60c0 33.1371-26.8629 60-60 60s-60-26.8629-60-60 26.8629-60 60-60 60 26.8629 60 60z" fill="#cd201f"></path><path d="m25 49c0-7.732 6.268-14 14-14h42c7.732 0 14 6.268 14 14v22c0 7.732-6.268 14-14 14h-42c-7.732 0-14-6.268-14-14z" fill="#fff"></path><path d="m74 59.5-21 10.8253v-21.6506z" fill="#cd201f"></path></svg></span>
  <span class="text1">Explore</span>
  <span class="text2">Visit Channel</span> 
</button>
      </div>
      
      <div class="blob"></div>
</div>

<div class="card" data-aos= 'fade-right'>
      <div class="bg"> 
      <img src={tiu} alt="creator graphics" />
      <h2>
      THE INDIAN UNBOXER <br/> <span>( 4.02M )</span></h2>

<button className='Explore-Button' onClick={redirectToExternalLink11}> 
  <span class="icon"><svg fill="none" height="33" viewBox="0 0 120 120" width="33" xmlns="http://www.w3.org/2000/svg"><path d="m120 60c0 33.1371-26.8629 60-60 60s-60-26.8629-60-60 26.8629-60 60-60 60 26.8629 60 60z" fill="#cd201f"></path><path d="m25 49c0-7.732 6.268-14 14-14h42c7.732 0 14 6.268 14 14v22c0 7.732-6.268 14-14 14h-42c-7.732 0-14-6.268-14-14z" fill="#fff"></path><path d="m74 59.5-21 10.8253v-21.6506z" fill="#cd201f"></path></svg></span>
  <span class="text1">Explore</span>
  <span class="text2">Visit Channel</span> 
</button>
      </div>
      
      <div class="blob"></div>
</div>
<div class="card" data-aos= 'fade-right'>
      <div class="bg"> 
      <img src={sumitcool} alt="creator graphics" />
      <h2>
      Sumit Cool Dubey <br/> <span>( 3.17M )</span></h2>

<button className='Explore-Button' onClick={redirectToExternalLink12}> 
  <span class="icon"><svg fill="none" height="33" viewBox="0 0 120 120" width="33" xmlns="http://www.w3.org/2000/svg"><path d="m120 60c0 33.1371-26.8629 60-60 60s-60-26.8629-60-60 26.8629-60 60-60 60 26.8629 60 60z" fill="#cd201f"></path><path d="m25 49c0-7.732 6.268-14 14-14h42c7.732 0 14 6.268 14 14v22c0 7.732-6.268 14-14 14h-42c-7.732 0-14-6.268-14-14z" fill="#fff"></path><path d="m74 59.5-21 10.8253v-21.6506z" fill="#cd201f"></path></svg></span>
  <span class="text1">Explore</span>
  <span class="text2">Visit Channel</span> 
</button>
      </div>
      
      <div class="blob"></div>
</div>


<div class="card" data-aos= 'fade-right'>
      <div class="bg"> 
      <img src={lakshay} alt="creator graphics" />
      <h2>Lakshay Chaudhary <br/> <span>( 2.75M )</span>

      </h2>

<button className='Explore-Button' onClick={redirectToExternalLink13}> 
  <span class="icon"><svg fill="none" height="33" viewBox="0 0 120 120" width="33" xmlns="http://www.w3.org/2000/svg"><path d="m120 60c0 33.1371-26.8629 60-60 60s-60-26.8629-60-60 26.8629-60 60-60 60 26.8629 60 60z" fill="#cd201f"></path><path d="m25 49c0-7.732 6.268-14 14-14h42c7.732 0 14 6.268 14 14v22c0 7.732-6.268 14-14 14h-42c-7.732 0-14-6.268-14-14z" fill="#fff"></path><path d="m74 59.5-21 10.8253v-21.6506z" fill="#cd201f"></path></svg></span>
  <span class="text1">Explore</span>
  <span class="text2">Visit Channel</span> 
</button>
      </div>
      
      <div class="blob"></div>
</div>
   </div>
</div>
<SlideBrand/>
<HomeContactForm/>


   
       

        </>
    );
};

export default Home;
