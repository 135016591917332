import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './HomeContactForm.css';

const HomeContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    inquiryType: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      setFormData(prevState => ({ ...prevState, [name]: value.replace(/\D/g, '') }));
    } else {
      setFormData(prevState => ({ ...prevState, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // The IDs you've obtained from EmailJS
    const serviceID = 'service_yxb9m1n';
    const templateID = 'template_27esavn';
    const userID = 'pjniIkUaIPC_MDfie';

    emailjs.sendForm(serviceID, templateID, e.target, userID)
      .then((result) => {
          console.log(result.text);
          alert('Message sent successfully!');
          setFormData({
            name: '',
            phone: '',
            email: '',
            inquiryType: '',
            subject: '',
            message: ''
          }); // Clear form after submission
      }, (error) => {
          console.log(error.text);
          alert('Failed to send the message, please try again.');
      });
  };

  return (
    <div className="form-container">
      <h1>Connect for Influencer Marketing Success</h1>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Full Name"
          />
        </div>
        <div className="form-group">
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="96XXXXXXX"
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email Address"
          />
        </div>
        <div className="form-group">
          <select
            name="inquiryType"
            value={formData.inquiryType}
            onChange={handleChange}
            placeholder="I am..."
          >
            <option value="">I am...</option>
            <option value="influencer">An Influencer</option>
            <option value="brand">Looking to Hire Influencers</option>
          </select>
        </div>
        <div className="form-group">
          <input
            type="text"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            placeholder="Subject"
          />
        </div>
        <div className="form-group">
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Your Message"
          />
        </div>
        <button type="submit">Submit Inquiry</button>
      </form>
    </div>
  );
};

export default HomeContactForm;
