import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './Components/Header/Header'; // Adjust the import path as needed
import Home from './Components/Home/Home'
import Services from './Components/Services/Services'
import HomeFooter from './Components/Footer/HomeFooter'


import './App.css'; // Assuming you have a CSS file for App

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Home />
      <Routes>
    
        <Route path="/services" element={<Services />} />
        {/* <Route path="/influencers" element={<Influencers />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} /> */}
      </Routes>
      <HomeFooter/>
    </BrowserRouter>
  );
};

export default App;
